import React from "react"
import Img from "gatsby-image"

import TextSection from "../../objects/TextSection"

import { Section, 
         Container, 
         Column,
         DestakPhoto,
         GalleryPhoto,
         Title,
         Subtitle } from "./styles"

const OurShopSection = ({ className, imageMaster, childrenImage }) => {
   return (
      <Section className={className}>
         <Container>
            <Column className="titulo">
               <Title titlepage="" titlesection="Nossa Loja" />
            </Column>
            <Column className="imagem">
               <DestakPhoto>
                  <Img fluid={imageMaster} />
               </DestakPhoto>
               <GalleryPhoto>
                  {childrenImage}
               </GalleryPhoto>
            </Column>
            <Column className="texto">
               <Subtitle 
                  subtitle="1.000 metros quadrados completa com um show room" />
               <TextSection>
                  Rosa Designer é uma loja completa com um show room que vai encantar muito todos os nossos visitantes e trabalha com as melhores indústrias e marcas de móveis nacionais e internacionais.
               </TextSection>
            </Column>
         </Container>
      </Section>
   )
}

export default OurShopSection