import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"

import Layout from "../../components/Layout";
import SEO from "../../components/seo"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import AboutSection from "../../components/AboutSection"
import OurShopSection from "../../components/OurShopSection"
import InviteSection from "../../components/InviteSection"

const QuemSomos = () => {

   const imgContent = useStaticQuery (
      graphql`
         query {
            imgAbout: allFile(
                  sort: {order: ASC, fields: 
                     childImageSharp___fluid___originalName},
                  filter: {relativeDirectory: {eq: "quem-somos"}}) {
               edges {
                 node {
                   childImageSharp {
                     fluid(maxWidth: 472) {
                        ...GatsbyImageSharpFluid
                     }
                   }
                 }
               }
            }
         }
      `
   )

   return (
      <Layout>
         <SEO title="Quem somos"/>
         <Header title=""/>
         <AboutSection 
            className=""
            imageMaster={imgContent.imgAbout.edges[0].node.childImageSharp.fluid}
            childrenImage={[
               <Img fluid={imgContent.imgAbout.edges[1].node.childImageSharp.fluid} />,
               <Img fluid={imgContent.imgAbout.edges[2].node.childImageSharp.fluid} />
            ]}
         />
         <OurShopSection 
            className="-variant"
            imageMaster={imgContent.imgAbout.edges[3].node.childImageSharp.fluid}
            childrenImage={[]}
         />
         <InviteSection />
         <Footer />
      </Layout>
   )
}

export default QuemSomos;