import React from "react"
import Img from "gatsby-image"

import TextSection from "../../objects/TextSection"

import { Section, 
         Container, 
         Column,
         Title,
         Subtitle,
         DestakPhoto,
         GalleryPhoto } from "./styles"

const AboutSection = ({ className, imageMaster, childrenImage }) => {
   return (
      <Section className={className}>
         <Container>
            <Column className="titulo">
               <Title titlepage="Quem somos" titlesection="" />
               <Subtitle subtitle="Design, conforto e requinte" />
            </Column>
            <Column className="imagem">
               <DestakPhoto>
                  <Img fluid={imageMaster} />
               </DestakPhoto>
               <GalleryPhoto>
                  {childrenImage}
               </GalleryPhoto>
            </Column>
            <Column className="texto">
               <TextSection>
                  A Rosa Designer é uma loja completa com um show room que vai encantar muito todos os nossos visitantes. Sabe porquê?
               </TextSection>
               <TextSection>
                  Porque a equipe da Rosa garimpa o que há de melhor no mercado de móveis e decoração e trabalha com as melhores indústrias e marcas de móveis nacionais e internacionais.
               </TextSection>
               <TextSection>
                  Aqui você vai encontrar móveis internos e externos, papel de parede, cortinas, tapetes, serviço de tapeçaria, móveis de madeira de demolição, almofadas, entre muitos itens que são essenciais para uma casa linda e acolhedora.
               </TextSection>
               <TextSection>
                  Nosso mobiliário é de origem brasileira, produzido com ética e respeito ao meio ambiente.
               </TextSection>
               <TextSection>
                  Garantimos a qualidade, beleza e a durabilidade dos nossos produtos.
               </TextSection>
               <TextSection>
                  Prezamos pela satisfação de nossos clientes e não abrimos mão de um bom atendimento durante e no pós-venda.
               </TextSection>
            </Column>
         </Container>
      </Section>
   )
}

export default AboutSection