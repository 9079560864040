import styled from "styled-components";
import media from "../../styles/breakPoints"

import MainSection from "../../objects/MainSection"
import TitleSection from "../../objects/TitleSection"
import SubtitleSection from "../../objects/SubtitleSection"

// STRUCTURE
const Section = styled(MainSection)`
   width: 100%;
   background-color: ${props => props.theme.color.secondyLight1};
   &.-variant {
      background-color: transparent;
   }
`;

const Container = styled.div`
   width: 100%;
   max-width: 1056px;
   padding: 1.6rem;
   margin: 0 auto;

   ${media.greaterThan("md")`
      display: grid;
      grid-template: "title title"
                     "img txt";
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      grid-column-gap: 2.4rem;
      padding: 2.4rem;
   `}

   ${media.greaterThan("xl")`
      grid-template: "title img txt";
      grid-template-columns: 1fr 472px 1fr;
      grid-column-gap: 2.4rem;
      padding: 3.2rem;
   `}

`

const Column = styled.div`

   ${media.greaterThan("md")`
      &.titulo { grid-area: title; }
      &.imagem { grid-area: img; }
      &.texto { grid-area: txt; }
  `}

`

// AREA IMAGES
const DestakPhoto = styled.div`
  width: 100%;
  height: calc(width);
  margin-bottom: 1.6rem;
`

const GalleryPhoto = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1.6rem;
  margin-bottom: 1.6rem;
`

// AREA TEXT
const Title = styled(TitleSection)`

   ${media.greaterThan("md")`
      font-size: ${props => props.theme.size.veryBig};
      line-height: 4.8rem;
   `}

   ${media.greaterThan("xl")`
      text-align: right;
   `}

`

const Subtitle = styled(SubtitleSection)`
   text-align: left;
   padding-left: 0;
`

export { 
  Section, 
  Container, 
  Column,
  DestakPhoto,
  GalleryPhoto,
  Title,
  Subtitle
}